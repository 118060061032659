import * as React from "react";
import { graphql, PageProps } from "gatsby";
import { paramCase } from "change-case";
import { BasePage, Maybe, RawBody } from "@/types/graphql";
import { RenderFAQS } from "@/components/RenderFAQS";
import { getLabel, isLive } from "@/utils";
import { Main } from "@/components/Main";

import { heroAtom, seoAtom } from "@/store";

import { Layout } from "@components/Layout";
import { Container } from "@components/Layout/Container";
import { Header } from "@components/Header";
import { Title } from "@components/Header/Title";
import { BreadCrumbs } from "@components/Breadcrumbs";
import { Helmet } from "react-helmet";
import { useHydrateAtoms } from "jotai/utils";

type FAQ = {
  question: string;
  _rawAnswer: RawBody[];
};

export type PageTemplateProps = {
  data: {
    hero: any;
    sanityFaqPage: BasePage & {
      hero: any;
      heading: Maybe<string>;
      publicationStatus: Maybe<string>;
      faqs: FAQ[];
    };
  };
} & PageProps;

const getHash = (phrase: string) => `#${paramCase(phrase)}`;

const PageTemplate = ({ data }: PageTemplateProps) => {
  const { faqs, seo } = data.sanityFaqPage;
  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null);
  const heading = getLabel(data.sanityFaqPage, "");

  const bodyClassName = isLive(data.sanityFaqPage) ? "" : "draft";

  const sidebarItemProps = faqs.map((faq, i) => ({
    label: faq.question,
    href: getHash(faq.question),
    onClick: (): void => setSelectedIndex(i),
  }));
  useHydrateAtoms([
    [heroAtom, data.sanityFaqPage?.hero ?? data?.hero],
    [seoAtom, seo],
  ]);
  return (
    <>

      <Helmet>
        <title> FAQ | EP3 Guide</title>
      </Helmet>
      <Layout>
        <Container>
          <Header>
            <BreadCrumbs
              paths={[
                { url: "/", label: "Home" },
                { url: "/about", label: "About" },
              ]}
              title={heading}
            />
            <Title>{heading}</Title>
          </Header>
          <Main isDisplayed={true} sidebarItemProps={sidebarItemProps}>
            <div className={`px-2 mb-8 md:px-8 ${bodyClassName}`}>
              <RenderFAQS faqs={faqs} selectedIndex={selectedIndex} />
            </div>
          </Main>
        </Container>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "hero-random-page.jpg" }) {
      ...heroImageFragment
    }

    sanityFaqPage(slug: { current: { eq: "about/faq" } }) {
      heading
      seo {
        title
        description
      }
      slug {
        current
      }
      publicationStatus
      faqs {
        question
        _rawAnswer(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`;

export default PageTemplate;
